import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';


function Footer() {
  return (
    <footer className='footer'>
      <div className='social-icons'>
        <a href='https://www.instagram.com/papodepoltrona/' target='_blank' rel='noopener noreferrer'>
          <i className="fa-brands fa-instagram"></i>
        </a>
        <a href='https://open.spotify.com/show/0DKsU405kTXDk907muqbz1?si=13928b2fb42b484e' target='_blank' rel='noopener noreferrer'>
          <i className="fa-brands fa-spotify"></i>
        </a>
      </div>
      <div className='footer-text'>
        Todas as imagens e vídeos aqui apresentados pertencem aos seus devidos produtores, estúdios e artistas.
        <br />
        Copyright © 2023 Todos os direitos reservados | Papo de Poltrona
        <br />
        Site desenvolvido por <a href='https://rpapadev.github.io/portifolio/' target='_blank' rel='noopener noreferrer'>Open Road</a>
        <br />
        <Link to="/admin">Painel Admin</Link>
      </div>
    </footer>
  );
}

export default Footer;