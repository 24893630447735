import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../layout/Series.css';


function Series() {
  const [series, setSeries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const seriesPerPage = 20;

  useEffect(() => {
    fetch("https://backpdp.onrender.com/api/series")
      .then((response) => response.json())
      .then((data) => setSeries(data))
      .catch((error) => console.error(error));

    const exampleSeries = Array.from({ length: 100 }, (_, index) => ({
      id: index + 1,
      serie_imagem: `https://via.placeholder.com/150?text=Serie${index + 1}`,
    }));

    setSeries(exampleSeries);
  }, []);

  // Função para dividir as séries em páginas com base no limite
  const indexOfLastSerie = currentPage * seriesPerPage;
  const indexOfFirstSerie = indexOfLastSerie - seriesPerPage;
  const currentSeries = series.slice(indexOfFirstSerie, indexOfLastSerie);

  // Função para mudar a página atual
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="series-container">
      <h1><span>|</span> Todas as Séries</h1>
      <div className="series-list">
        {currentSeries.map((serie) => (
          <div key={serie.serie_id} className="serie-item">
            <Link to={`/series/${serie.serie_id}`} key={serie.serie_id}>
              <img src={serie.serie_imagem} alt={`${serie.serie_titulo}`} />
            </Link>
          </div>
        ))}
      </div>
      <div className="pagination">
        {Array.from({ length: Math.ceil(series.length / seriesPerPage) }).map((_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? "current" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );

}

export default Series;