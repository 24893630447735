import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../layout/Painel.css';

function Painel() {
    const [activeSection, setActiveSection] = useState('series');
    const [data, setData] = useState([]);
    const fetchData = async () => {
        try {
            const response = await fetch(`https://backpdp.onrender.com/api/${activeSection}`);
            const jsonData = await response.json();
            setData(jsonData);
        } catch (error) {
            console.error('Erro ao buscar os dados:', error);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [activeSection]);

    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://backpdp.onrender.com/api/${activeSection}/${id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                fetchData();
                console.log(`Item com ID ${id} excluído com sucesso.`);
            } else {
                console.error(`Erro ao excluir o item com ID ${id}`);
            }
        } catch (error) {
            console.error(`Erro ao excluir o item com ID ${id}:`, error);
        }
    };

    return (
        <div className="admin-panel">
            <div className="admin-sidebar">
                <h2>Painel de administração</h2>
                <ul>
                    <li
                        className={activeSection === 'series' ? 'active' : ''}
                        onClick={() => handleSectionChange('series')}
                    >
                        <i className="fa-solid fa-arrow-right"></i> Séries
                    </li>
                    <li
                        className={activeSection === 'filmes' ? 'active' : ''}
                        onClick={() => handleSectionChange('filmes')}
                    >
                        <i className="fa-solid fa-arrow-right"></i> Filmes
                    </li>
                    <li
                        className={activeSection === 'noticias' ? 'active' : ''}
                        onClick={() => handleSectionChange('noticias')}
                    >
                        <i className="fa-solid fa-arrow-right"></i> Notícias
                    </li>
                </ul>
            </div>
            <div className="admin-content">
                <h2>
                    {activeSection === 'series'
                        ? 'Séries'
                        : activeSection === 'filmes'
                            ? 'Filmes'
                            : 'Notícias'}
                </h2>
                <h3>
                    {activeSection === 'series'
                        ? <Link to="/admin/painel/criar/serie">+ Adicionar nova Série</Link>
                        : activeSection === 'filmes'
                            ? <Link to="/admin/painel/criar/filme">+ Adicionar novo Filme</Link>
                            : <Link to="/admin/painel/criar/noticia">+ Adicionar nova Notícia</Link>}
                </h3>
                <ul>
                    {data.map((item) => (
                        <li key={item.id}>
                            {activeSection === 'series' && (
                                <>
                                    {item.serie_titulo}
                                    <Link to={`/admin/painel/editar/serie/${item.serie_id}`}>
                                        <button className='edit'>Editar</button>
                                    </Link>
                                    <button className="delete" onClick={() => handleDelete(item.serie_id)}>Excluir</button>
                                </>
                            )}
                            {activeSection === 'filmes' && (
                                <>
                                    {item.filme_titulo}
                                    <Link to={`/admin/painel/editar/filme/${item.filme_id}`}>
                                        <button className='edit'>Editar</button>
                                    </Link>
                                    <button className="delete" onClick={() => handleDelete(item.filme_id)}>Excluir</button>
                                </>
                            )}
                            {activeSection === 'noticias' && (
                                <>
                                    {item.noticia_titulo}
                                    <Link to={`/admin/painel/editar/noticia/${item.noticia_id}`}>
                                        <button className='edit'>Editar</button>
                                    </Link>
                                    <button className="delete" onClick={() => handleDelete(item.noticia_id)}>Excluir</button>
                                </>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Painel;