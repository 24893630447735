import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../layout/Filmes.css';


function Filmes() {
    const [filmes, setFilmes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const moviesPerPage = 20;

    useEffect(() => {
        fetch("https://backpdp.onrender.com/api/filmes")
            .then((response) => response.json())
            .then((data) => setFilmes(data))
            .catch((error) => console.error(error));

        const exampleMovies = Array.from({ length: 100 }, (_, index) => ({
            id: index + 1,
            serie_imagem: `https://via.placeholder.com/150?text=Serie${index + 1}`,
        }));

        setFilmes(exampleMovies);
    }, []);

    // Função para dividir as séries em páginas com base no limite
    const indexOfLastMovie = currentPage * moviesPerPage;
    const indexOfFirstMovie = indexOfLastMovie - moviesPerPage;
    const currentMovies = filmes.slice(indexOfFirstMovie, indexOfLastMovie);

    // Função para mudar a página atual
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="filmes-container">
            <h1><span>|</span> Todos os Filmes</h1>
            <div className="filmes-list">
                {currentMovies.map((filme) => (
                    <div key={filme.filme_id} className="filme-item">
                        <Link to={`/filmes/${filme.filme_id}`} key={filme.filme_id}>
                            <img src={filme.filme_imagem} alt={`${filme.filme_titulo}`} />
                        </Link>
                    </div>
                ))}
            </div>
            <div className="pagination">
                {Array.from({ length: Math.ceil(filmes.length / moviesPerPage) }).map((_, index) => (
                    <button
                        key={index}
                        onClick={() => paginate(index + 1)}
                        className={currentPage === index + 1 ? "current" : ""}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );

}

export default Filmes;