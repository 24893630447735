import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../layout/ResultadosPesquisa.css';

function ResultadosPesquisa() {
    const location = useLocation();
    const [resultadosSeries, setResultadosSeries] = useState([]);
    const [resultadosFilmes, setResultadosFilmes] = useState([]);
    const [resultadosNoticias, setResultadosNoticias] = useState([]);

    useEffect(() => {
        // Use o objeto de localização para obter o termo de pesquisa da URL
        const termoPesquisa = new URLSearchParams(location.search).get('termo');

        // Faça solicitações à sua API com o termo de pesquisa e atualize os resultados separadamente
        async function fetchResultadosPesquisa() {
            try {
                // Solicitação para séries
                const responseSeries = await fetch(`https://backpdp.onrender.com/api/pesquisar/series?termo=${termoPesquisa}`);
                const dataSeries = await responseSeries.json();
                setResultadosSeries(dataSeries);

                // Solicitação para filmes
                const responseFilmes = await fetch(`https://backpdp.onrender.com/api/pesquisar/filmes?termo=${termoPesquisa}`);
                const dataFilmes = await responseFilmes.json();
                setResultadosFilmes(dataFilmes);

                // Solicitação para notícias
                const responseNoticias = await fetch(`https://backpdp.onrender.com/api/pesquisar/noticias?termo=${termoPesquisa}`);
                const dataNoticias = await responseNoticias.json();
                setResultadosNoticias(dataNoticias);
            } catch (error) {
                console.error('Erro ao buscar resultados de pesquisa:', error);
            }
        }

        if (termoPesquisa) {
            fetchResultadosPesquisa();
        }
    }, [location.search]);

    return (
        <div className='return-pesquisa'>
            <h2><span>| </span>Resultados da Pesquisa</h2>

            {/* Seção de Séries */}
            <section>
                <h3>Séries</h3>
                <ul>
                    {resultadosSeries.map((resultado) => (
                        <li key={resultado.serie_id}>
                            <a href={`/series/${resultado.serie_id}`}>
                                <img src={resultado.serie_imagem} alt={resultado.serie_titulo} />
                                {resultado.serie_titulo}
                            </a>
                        </li>
                    ))}
                </ul>
            </section>

            {/* Seção de Filmes */}
            <section>
                <h3>Filmes</h3>
                <ul>
                    {resultadosFilmes.map((resultado) => (
                        <li key={resultado.filme_id}>
                            <a href={`/filmes/${resultado.filme_id}`}>
                                <img src={resultado.filme_imagem} alt={resultado.filme_titulo} />
                                {resultado.filme_titulo}
                            </a>
                        </li>
                    ))}
                </ul>
            </section>

            {/* Seção de Notícias */}
            <section>
                <h3>Notícias</h3>
                <ul>
                    {resultadosNoticias.map((resultado) => (
                        <li key={resultado.noticia_id}>
                            <a href={`/noticias/${resultado.noticia_id}`}>
                                <img src={resultado.noticia_imagem} alt={resultado.noticia_titulo} />
                                {resultado.noticia_titulo}
                            </a>
                        </li>
                    ))}
                </ul>
            </section>
        </div>
    );
}

export default ResultadosPesquisa;