import React, { useState, useEffect } from 'react';
import '../layout/Home.css';
import { Link } from 'react-router-dom';

function Home() {
  const [noticias, setNoticias] = useState([]);
  const [series, setSeries] = useState([]);
  const [filmes, setFilmes] = useState([]);

  async function getNoticias() {
    try {
      const response = await fetch("https://backpdp.onrender.com/api/noticias");
      const jsonData = await response.json();

      setNoticias(jsonData);
    } catch (err) {
      console.error(err.message)
    }
  }

  async function getSeries() {
    try {
      const response = await fetch("https://backpdp.onrender.com/api/series");
      const jsonData = await response.json();
      const ultimasSeries = jsonData.slice(0, 4); // Pegar os 4 primeiros
      setSeries(ultimasSeries);
    } catch (err) {
      console.error(err.message)
    }
  }

  async function getFilmes() {
    try {
      const response = await fetch("https://backpdp.onrender.com/api/filmes");
      const jsonData = await response.json();
      const ultimosFilmes = jsonData.slice(0, 4); // Pegar os 4 primeiros
      setFilmes(ultimosFilmes);
    } catch (err) {
      console.error(err.message)
    }
  }

  useEffect(() => {
    // Buscar as últimas notícias
    getNoticias();

    // Buscar as últimas avaliações de séries
    getSeries();

    // Buscar as últimas avaliações de filmes
    getFilmes();

  }, []);

  return (
    <div className='home'>
      {/* Seção de últimas notícias */}
      <section className='latest-news'>
        <h2><span>|</span> Últimas Notícias</h2>
        <div className='news-container'>
          <div className='big-news'>
            <div className='news-big-item'>
              <Link to={`/noticias/${noticias[0]?.noticia_id}`}>
                <img src={noticias[0]?.noticia_imagem} alt={noticias[0]?.noticia_titulo} />
                <h3>{noticias[0]?.noticia_titulo}</h3>
              </Link>
            </div>
          </div>
          <div className='small-news'>
            <div className='news-item'>
              <Link to={`/noticias/${noticias[1]?.noticia_id}`}>
                <img src={noticias[1]?.noticia_imagem} alt={noticias[1]?.noticia_titulo} />
                <h3>{noticias[1]?.noticia_titulo}</h3>
              </Link>
            </div>
            <div className='news-item'>
              <Link to={`/noticias/${noticias[2]?.noticia_id}`}>
                <img src={noticias[2]?.noticia_imagem} alt={noticias[2]?.noticia_titulo} />
                <h3>{noticias[2]?.noticia_titulo}</h3>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Seção de últimas avaliações */}
      <section className='latest-reviews'>
        <h2><span>|</span> Últimas Avaliações</h2>
        <div className='reviews-container'>
          <div className='series-reviews'>
            {series.map(review => (
              <div key={review.serie_id} className='review-item'>
                <Link to={`/series/${review.serie_id}`}>
                  <img src={review.serie_imagem} alt={`${review.serie_titulo}`} />
                </Link>
              </div>
            ))}
          </div>
          <div className='movies-reviews'>
            {filmes.map(review => (
              <div key={review.filme_id} className='review-item'>
                <Link to={`/filmes/${review.filme_id}`}>
                  <img src={review.filme_imagem} alt={`${review.filme_titulo}`} />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;