import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../layout/SingleFilme.css';



function SingleFilme() {
    const { id } = useParams(); // Obtenha o parâmetro de ID da URL
    const [filme, setFilme] = useState({});

    useEffect(() => {
        fetch(`https://backpdp.onrender.com/api/filmes/${id}`)
            .then((response) => response.json())
            .then((data) => setFilme(data))
            .catch((error) => console.error(error));
    }, [id]);

    return (
        <div className="singleFilme">
            <img src={filme.filme_imagem} alt={filme.filme_titulo} />
            <div className="info">
                <h1>{filme.filme_titulo}</h1>
                <h2>Nota: <span>{filme.filme_nota}</span></h2>
                <h3>Gêneros: <span>{filme.filme_genero1}, {filme.filme_genero2}, {filme.filme_genero3}</span></h3>
                <h3>Ano: <span>{filme.filme_ano}</span></h3>
                <p>Descrição: <span>{filme.filme_descricao}</span></p>
            </div>
            <div className='comentarioFilme'>
                <p>{filme.filme_comentario}</p>
            </div>
        </div>
    );
}

export default SingleFilme;