import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Altere aqui
import './Navbar.css';

import logo from '../../img/logopdp.png';

function Navbar() {
  const [termoPesquisa, setTermoPesquisa] = useState('');
  const navigate = useNavigate(); // Altere aqui

  const handlePesquisa = () => {
    // Navegue para a página de resultados de pesquisa com o termo de pesquisa como parâmetro
    navigate(`/pesquisa?termo=${termoPesquisa}`); // Altere aqui
  };

  return (
    <nav className='header'>
      <div className='container'>
        <div className='links'>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/series">Séries</Link>
            </li>
            <li>
              <Link to="/filmes">Filmes</Link>
            </li>
            <li>
              <Link to="/noticias">Notícias</Link>
            </li>
            <li>
              <a
                href="https://open.spotify.com/show/0DKsU405kTXDk907muqbz1?si=13928b2fb42b484e"
                target="_blank"
                rel="noopener noreferrer"
              >
                Podcast PdP
              </a>
            </li>
          </ul>
          <div className='searchbar'>
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              placeholder="Buscar séries ou filmes..."
              value={termoPesquisa}
              onChange={(e) => setTermoPesquisa(e.target.value)}
            />
            <button onClick={handlePesquisa}>Pesquisar</button>
          </div>
        </div>
        <div className='logo-container'>
          <img src={logo} alt='Logo Papo de Poltrona' />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
