import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../layout/PaginaDeEdicaoFilme.css';

function PaginaDeAdicaoNoticia() {
    const navigate = useNavigate();
    const [novaNoticia, setNovaNoticia] = useState({
        noticia_titulo: '',
        noticia_descricao: '',
        noticia_imagem: '',
        noticia_autor: '',
    });


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`https://backpdp.onrender.com/api/noticias/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(novaNoticia),
            });

            if (response.ok) {
                navigate('/admin/painel');
            } else {
                console.error('Erro ao criar a notícia.');
            }
        } catch (error) {
            console.error('Erro ao criar a notícia:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNovaNoticia({
            ...novaNoticia,
            [name]: value,
        });
    };

    return (
        <div className="form-container">
            <h2>Adicionar Notícia</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="noticia_titulo">Título</label>
                    <input
                        type="text"
                        id="noticia_titulo"
                        name="noticia_titulo"
                        value={novaNoticia.noticia_titulo}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="noticia_descricao">Descrição</label>
                    <textarea
                        type="text"
                        id="noticia_descricao"
                        name="noticia_descricao"
                        value={novaNoticia.noticia_descricao}
                        onChange={handleChange}
                        rows="4"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="noticia_imagem">URL da Imagem</label>
                    <input
                        type="text"
                        id="noticia_imagem"
                        name="noticia_imagem"
                        value={novaNoticia.noticia_imagem}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="noticia_autor">Autor</label>
                    <input
                        type="text"
                        id="noticia_autor"
                        name="noticia_autor"
                        value={novaNoticia.noticia_autor}
                        onChange={handleChange}
                    />
                </div>
                <div className="btn-container">
                    <button type="submit">Salvar</button>
                </div>
            </form>
        </div>
    );
}

export default PaginaDeAdicaoNoticia;