import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Series from './components/pages/Series';
import SingleSerie from './components/pages/SingleSerie';
import Filmes from './components/pages/Filmes';
import SingleFilme from './components/pages/SingleFilme';
import Noticias from './components/pages/Noticias';
import SingleNoticia from './components/pages/SingleNoticia';
import AdminLogin from './components/pages/AdminLogin';
import Painel from './components/pages/Painel';
import PaginaDeEdicaoSerie from './components/pages/PaginaDeEdicaoSerie';
import PaginaDeEdicaoFilme from './components/pages/PaginaDeEdicaoFilme';
import PaginaDeEdicaoNoticia from './components/pages/PaginaDeEdicaoNoticia';
import PaginaDeAdicaoSerie from './components/pages/PaginaDeAdicaoSerie';
import PaginaDeAdicaoFilme from './components/pages/PaginaDeAdicaoFilme';
import PaginaDeAdicaoNoticia from './components/pages/PaginaDeAdicaoNoticia';
import ResultadosPesquisa from './components/pages/ResultadosPesquisa';
import PrivateRoute from './components/pages/PrivateRoute';
import { AuthProvider } from './components/pages/AuthContext';



import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';


function App() {
  return (
    <div>
      <Router>
        <AuthProvider>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/series" element={<Series />} />
            <Route path="/series/:id" element={<SingleSerie />} />
            <Route path="/filmes" element={<Filmes />} />
            <Route path="/filmes/:id" element={<SingleFilme />} />
            <Route path="/noticias" element={<Noticias />} />
            <Route path="/noticias/:id" element={<SingleNoticia />} />
            <Route path="/admin" element={<AdminLogin />} />
            <Route
              path="/admin/painel"
              element={
                <PrivateRoute>
                  <Painel />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/painel/editar/serie/:id"
              element={
                <PrivateRoute>
                  <PaginaDeEdicaoSerie />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/painel/editar/filme/:id"
              element={
                <PrivateRoute>
                  <PaginaDeEdicaoFilme />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/painel/editar/noticia/:id"
              element={
                <PrivateRoute>
                  <PaginaDeEdicaoNoticia />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/painel/criar/serie"
              element={
                <PrivateRoute>
                  <PaginaDeAdicaoSerie />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/painel/criar/filme"
              element={
                <PrivateRoute>
                  <PaginaDeAdicaoFilme />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/painel/criar/noticia"
              element={
                <PrivateRoute>
                  <PaginaDeAdicaoNoticia />
                </PrivateRoute>
              }
            />
            <Route path="/pesquisa" element={<ResultadosPesquisa />} />
          </Routes>
          <Footer />
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;