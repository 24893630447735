import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../layout/SingleSerie.css';



function SingleSerie() {
    const { id } = useParams(); // Obtenha o parâmetro de ID da URL
    const [serie, setSerie] = useState({});

    useEffect(() => {
        fetch(`https://backpdp.onrender.com/api/series/${id}`)
            .then((response) => response.json())
            .then((data) => setSerie(data))
            .catch((error) => console.error(error));
    }, [id]);

    return (
        <div className="singleSerie">
            <img src={serie.serie_imagem} alt={serie.serie_titulo} />
            <div className="info">
                <h1>{serie.serie_titulo}</h1>
                <h2>Nota: <span>{serie.serie_nota}</span></h2>
                <h3>Gêneros: <span>{serie.serie_genero1}, {serie.serie_genero2}, {serie.serie_genero3}</span></h3>
                <h3>Ano: <span>{serie.serie_ano}</span></h3>
                <p>Descrição: <span>{serie.serie_descricao}</span></p>
            </div>
            <div className='comentarioSerie'>
                <p>{serie.serie_comentario}</p>
            </div>
        </div>
    );
}

export default SingleSerie;