import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../layout/Noticias.css';

function Noticias() {
    const [noticias, setNoticias] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const noticiasPerPage = 20;

    useEffect(() => {
        fetch("https://backpdp.onrender.com/api/noticias")
            .then((response) => response.json())
            .then((data) => setNoticias(data))
            .catch((error) => console.error(error));

        const exampleNoticias = Array.from({ length: 100 }, (_, index) => ({
            id: index + 1,
            noticia_imagem: `https://via.placeholder.com/150?text=Noticia${index + 1}`,
            noticia_titulo: `Notícia ${index + 1}`,
        }));

        setNoticias(exampleNoticias);
    }, []);

    // Função para dividir as notícias em páginas com base no limite
    const indexOfLastNoticia = currentPage * noticiasPerPage;
    const indexOfFirstNoticia = indexOfLastNoticia - noticiasPerPage;
    const currentNoticias = noticias.slice(indexOfFirstNoticia, indexOfLastNoticia);

    // Função para mudar a página atual
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="noticias-container">
            <h1><span>|</span> Todas as Notícias</h1>
            <div className="noticias-list">
                {currentNoticias.map((noticia) => (
                    <div key={noticia.noticia_id} className="noticia-item">
                        <Link to={`/noticias/${noticia.noticia_id}`} key={noticia.noticia_id}>
                            <img src={noticia.noticia_imagem} alt={`Notícia ${noticia.noticia_id}`} />
                            <h2>{noticia.noticia_titulo}</h2>
                        </Link>
                    </div>
                ))}
            </div>
            <div className="pagination">
                {Array.from({ length: Math.ceil(noticias.length / noticiasPerPage) }).map((_, index) => (
                    <button
                        key={index}
                        onClick={() => paginate(index + 1)}
                        className={currentPage === index + 1 ? "current" : ""}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default Noticias;