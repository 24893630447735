// PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext"; // Importe o hook useAuth

function PrivateRoute({ children }) {
    const { isAuthenticated } = useAuth(); // Obtém o estado de autenticação do contexto

    return isAuthenticated ? children : <Navigate to="/admin" />;
}

export default PrivateRoute;