import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import '../layout/AdminLogin.css';

function AdminLogin() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [user_nome, setUserNome] = useState('');
  const [user_password, setUserPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://backpdp.onrender.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_nome, user_password }),
      });

      if (response.ok) {
        login();
        navigate('/admin/painel');
      } else {
        setErrorMessage('Nome de usuário ou senha incorretos. Tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
    }
  };

  return (
    <div className="admin-login-container">
      <h2>Login do Painel de Administração</h2>
      <form onSubmit={handleLogin}> {/* Adicione onSubmit ao formulário */}
        <input
          type="text"
          placeholder="Nome de Usuário"
          value={user_nome}
          onChange={(e) => setUserNome(e.target.value)}
        />
        <input
          type="password"
          placeholder="Senha"
          value={user_password}
          onChange={(e) => setUserPassword(e.target.value)}
        />
        <button type="submit">Entrar</button> {/* Defina o tipo do botão como 'submit' */}
      </form>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}

export default AdminLogin;