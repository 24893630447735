import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../layout/SingleNoticia.css';


function SingleNoticia() {
    const { id } = useParams(); // Obtenha o parâmetro de ID da URL
    const [noticia, setNoticia] = useState({});

    useEffect(() => {
        fetch(`https://backpdp.onrender.com/api/noticias/${id}`)
            .then((response) => response.json())
            .then((data) => setNoticia(data))
            .catch((error) => console.error(error));
    }, [id]);

    return (
        <div className="singleNoticia">
            <h1>{noticia.noticia_titulo}</h1>
            <img src={noticia.noticia_imagem} alt={noticia.noticia_titulo} />
            <h3>Autor: {noticia.noticia_autor}</h3>
            <p>{noticia.noticia_descricao}</p>
        </div>
    );
}

export default SingleNoticia;