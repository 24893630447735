import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../layout/PaginaDeEdicaoNoticia.css';

function PaginaDeEdicaoNoticia() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [noticia, setNoticia] = useState({
        noticia_titulo: '',
        noticia_descricao: '',
        noticia_imagem: '',
        noticia_autor: '',
    });

    const fetchNoticiaData = async () => {
        try {
            const response = await fetch(`https://backpdp.onrender.com/api/noticias/${id}`);
            const jsonData = await response.json();
            setNoticia(jsonData);
        } catch (error) {
            console.error('Erro ao buscar os dados da notícia:', error);
        }
    };

    useEffect(() => {
        fetchNoticiaData();
        // eslint-disable-next-line
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`https://backpdp.onrender.com/api/noticias/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(noticia),
            });

            if (response.ok) {
                navigate('/admin/painel');
            } else {
                console.error('Erro ao editar a notícia.');
            }
        } catch (error) {
            console.error('Erro ao editar a notícia:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNoticia({
            ...noticia,
            [name]: value,
        });
    };

    return (
        <div className="form-container">
            <h2>Editar Notícia</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="noticia_titulo">Título</label>
                    <input
                        type="text"
                        id="noticia_titulo"
                        name="noticia_titulo"
                        value={noticia.noticia_titulo}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="noticia_descricao">Descrição</label>
                    <textarea
                        type="text"
                        id="noticia_descricao"
                        name="noticia_descricao"
                        value={noticia.noticia_descricao}
                        onChange={handleChange}
                        rows="4"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="noticia_imagem">URL da Imagem</label>
                    <input
                        type="text"
                        id="noticia_imagem"
                        name="noticia_imagem"
                        value={noticia.noticia_imagem}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="noticia_autor">Autor</label>
                    <input
                        type="text"
                        id="noticia_autor"
                        name="noticia_autor"
                        value={noticia.noticia_autor}
                        onChange={handleChange}
                    />
                </div>
                <div className="btn-container">
                    <button type="submit">Salvar</button>
                </div>
            </form>
        </div>
    );
}

export default PaginaDeEdicaoNoticia;