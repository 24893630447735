import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../layout/PaginaDeEdicaoFilme.css';

function PaginaDeAdicaoFilme() {
    const navigate = useNavigate();
    const [novoFilme, setNovoFilme] = useState({
        filme_titulo: '',
        filme_nota: '',
        filme_genero1: '',
        filme_genero2: '',
        filme_genero3: '',
        filme_ano: '',
        filme_imagem: '',
        filme_descricao: '',
        filme_comentario: '',
    });


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`https://backpdp.onrender.com/api/filmes/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(novoFilme),
            });

            if (response.ok) {
                navigate('/admin/painel');
            } else {
                console.error('Erro ao criar o filme.');
            }
        } catch (error) {
            console.error('Erro ao criar o filme:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNovoFilme({
            ...novoFilme,
            [name]: value,
        });
    };

    return (
        <div className="form-container">
            <h2>Adicionar Filme</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="filme_titulo">Título</label>
                    <input
                        type="text"
                        id="filme_titulo"
                        name="filme_titulo"
                        value={novoFilme.filme_titulo}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="filme_nota">Nota</label>
                    <input
                        type="number"
                        id="filme_nota"
                        name="filme_nota"
                        value={novoFilme.filme_nota}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="filme_genero1">Gênero 1</label>
                    <select
                        id="filme_genero1"
                        name="filme_genero1"
                        value={novoFilme.filme_genero1}
                        onChange={handleChange}
                    >
                        <option value=" "> </option>
                        <option value="Ação">Ação</option>
                        <option value="Aventura">Aventura</option>
                        <option value="Comédia">Comédia</option>
                        <option value="Dança">Dança</option>
                        <option value="Documentário">Documentário</option>
                        <option value="Drama">Drama</option>
                        <option value="Espionagem">Espionagem</option>
                        <option value="Fantasia">Fantasia</option>
                        <option value="Ficção">Ficção</option>
                        <option value="Mistério">Mistério</option>
                        <option value="Musical">Musical</option>
                        <option value="Romance">Romance</option>
                        <option value="Suspense">Suspense</option>
                        <option value="Terror">Terror</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="filme_genero2">Gênero 2</label>
                    <select
                        id="filme_genero2"
                        name="filme_genero2"
                        value={novoFilme.filme_genero2}
                        onChange={handleChange}
                    >
                        <option value=" "> </option>
                        <option value="Ação">Ação</option>
                        <option value="Aventura">Aventura</option>
                        <option value="Comédia">Comédia</option>
                        <option value="Dança">Dança</option>
                        <option value="Documentário">Documentário</option>
                        <option value="Drama">Drama</option>
                        <option value="Espionagem">Espionagem</option>
                        <option value="Fantasia">Fantasia</option>
                        <option value="Ficção">Ficção</option>
                        <option value="Mistério">Mistério</option>
                        <option value="Musical">Musical</option>
                        <option value="Romance">Romance</option>
                        <option value="Suspense">Suspense</option>
                        <option value="Terror">Terror</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="filme_genero3">Gênero 3</label>
                    <select
                        id="filme_genero3"
                        name="filme_genero3"
                        value={novoFilme.filme_genero3}
                        onChange={handleChange}
                    >
                        <option value=" "> </option>
                        <option value="Ação">Ação</option>
                        <option value="Aventura">Aventura</option>
                        <option value="Comédia">Comédia</option>
                        <option value="Dança">Dança</option>
                        <option value="Documentário">Documentário</option>
                        <option value="Drama">Drama</option>
                        <option value="Espionagem">Espionagem</option>
                        <option value="Fantasia">Fantasia</option>
                        <option value="Ficção">Ficção</option>
                        <option value="Mistério">Mistério</option>
                        <option value="Musical">Musical</option>
                        <option value="Romance">Romance</option>
                        <option value="Suspense">Suspense</option>
                        <option value="Terror">Terror</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="filme_ano">Ano</label>
                    <input
                        type="number"
                        id="filme_ano"
                        name="filme_ano"
                        value={novoFilme.filme_ano}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="filme_imagem">URL da Imagem</label>
                    <input
                        type="text"
                        id="filme_imagem"
                        name="filme_imagem"
                        value={novoFilme.filme_imagem}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="filme_descricao">Descrição</label>
                    <textarea
                        type="text"
                        id="filme_descricao"
                        name="filme_descricao"
                        value={novoFilme.filme_descricao}
                        onChange={handleChange}
                        rows="4"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="filme_comentario">Comentário</label>
                    <textarea
                        type="text"
                        id="filme_comentario"
                        name="filme_comentario"
                        value={novoFilme.filme_comentario}
                        onChange={handleChange}
                        rows="4"
                    />
                </div>
                <div className="btn-container">
                    <button type="submit">Salvar</button>
                </div>
            </form>
        </div>
    );
}

export default PaginaDeAdicaoFilme;