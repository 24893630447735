import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../layout/PaginaDeEdicaoSerie.css';

function PaginaDeEdicaoSerie() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [serie, setSerie] = useState({
        serie_titulo: '',
        serie_nota: '',
        serie_genero1: '',
        serie_genero2: '',
        serie_genero3: '',
        serie_ano: '',
        serie_imagem: '',
        serie_descricao: '',
        serie_comentario: '',
    });

    const fetchSerieData = async () => {
        try {
            const response = await fetch(`https://backpdp.onrender.com/api/series/${id}`);
            const jsonData = await response.json();
            setSerie(jsonData);
        } catch (error) {
            console.error('Erro ao buscar os dados da série:', error);
        }
    };

    useEffect(() => {
        fetchSerieData();
        // eslint-disable-next-line
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`https://backpdp.onrender.com/api/series/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(serie),
            });

            if (response.ok) {
                navigate('/admin/painel');
            } else {
                console.error('Erro ao editar a série.');
            }
        } catch (error) {
            console.error('Erro ao editar a série:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSerie({
            ...serie,
            [name]: value,
        });
    };

    return (
        <div className="form-container">
            <h2>Editar Série</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="serie_titulo">Título</label>
                    <input
                        type="text"
                        id="serie_titulo"
                        name="serie_titulo"
                        value={serie.serie_titulo}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="serie_nota">Nota</label>
                    <input
                        type="number"
                        id="serie_nota"
                        name="serie_nota"
                        value={serie.serie_nota}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="serie_genero1">Gênero 1</label>
                    <select
                        id="serie_genero1"
                        name="serie_genero1"
                        value={serie.serie_genero1}
                        onChange={handleChange}
                    >
                        <option value="Ação">Ação</option>
                        <option value="Aventura">Aventura</option>
                        <option value="Comédia">Comédia</option>
                        <option value="Dança">Dança</option>
                        <option value="Documentário">Documentário</option>
                        <option value="Drama">Drama</option>
                        <option value="Espionagem">Espionagem</option>
                        <option value="Fantasia">Fantasia</option>
                        <option value="Ficção">Ficção</option>
                        <option value="Mistério">Mistério</option>
                        <option value="Musical">Musical</option>
                        <option value="Romance">Romance</option>
                        <option value="Suspense">Suspense</option>
                        <option value="Terror">Terror</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="serie_genero2">Gênero 2</label>
                    <select
                        id="serie_genero2"
                        name="serie_genero2"
                        value={serie.serie_genero2}
                        onChange={handleChange}
                    >
                        <option value="Ação">Ação</option>
                        <option value="Aventura">Aventura</option>
                        <option value="Comédia">Comédia</option>
                        <option value="Dança">Dança</option>
                        <option value="Documentário">Documentário</option>
                        <option value="Drama">Drama</option>
                        <option value="Espionagem">Espionagem</option>
                        <option value="Fantasia">Fantasia</option>
                        <option value="Ficção">Ficção</option>
                        <option value="Mistério">Mistério</option>
                        <option value="Musical">Musical</option>
                        <option value="Romance">Romance</option>
                        <option value="Suspense">Suspense</option>
                        <option value="Terror">Terror</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="serie_genero3">Gênero 3</label>
                    <select
                        id="serie_genero3"
                        name="serie_genero3"
                        value={serie.serie_genero3}
                        onChange={handleChange}
                    >
                        <option value="Ação">Ação</option>
                        <option value="Aventura">Aventura</option>
                        <option value="Comédia">Comédia</option>
                        <option value="Dança">Dança</option>
                        <option value="Documentário">Documentário</option>
                        <option value="Drama">Drama</option>
                        <option value="Espionagem">Espionagem</option>
                        <option value="Fantasia">Fantasia</option>
                        <option value="Ficção">Ficção</option>
                        <option value="Mistério">Mistério</option>
                        <option value="Musical">Musical</option>
                        <option value="Romance">Romance</option>
                        <option value="Suspense">Suspense</option>
                        <option value="Terror">Terror</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="serie_ano">Ano</label>
                    <input
                        type="number"
                        id="serie_ano"
                        name="serie_ano"
                        value={serie.serie_ano}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="serie_imagem">URL da Imagem</label>
                    <input
                        type="text"
                        id="serie_imagem"
                        name="serie_imagem"
                        value={serie.serie_imagem}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="serie_descricao">Descrição</label>
                    <textarea
                        type="text"
                        id="serie_descricao"
                        name="serie_descricao"
                        value={serie.serie_descricao}
                        onChange={handleChange}
                        rows="4"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="serie_comentario">Comentário</label>
                    <textarea
                        type="text"
                        id="serie_comentario"
                        name="serie_comentario"
                        value={serie.serie_comentario}
                        onChange={handleChange}
                        rows="4"
                    />
                </div>
                <div className="btn-container">
                    <button type="submit">Salvar</button>
                </div>
            </form>
        </div>
    );
}

export default PaginaDeEdicaoSerie;